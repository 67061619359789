var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"body"},[_vm._m(0),_c('div',{staticStyle:{"padding-top":"5vh"}}),_c('div',{staticClass:"bind-btn"},[_c('van-form',{on:{"submit":_vm.login}},[_c('van-field',{attrs:{"name":"用户名","label":"用户名","placeholder":"用户名","rules":[{ required: true, message: '请填写用户名' }]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('van-field',{attrs:{"type":"password","name":"密码","label":"密码","placeholder":"密码","rules":[{ required: true, message: '请填写密码' }]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticStyle:{"padding-top":"5vh"}}),_c('van-button',{attrs:{"color":"linear-gradient(to right, #ff6034, #ee0a24)","size":"large"}},[_vm._v("登入")])],1)],1),_c('div',{staticStyle:{"padding-top":"2vh","font-size":"1.8vh","color":"#c42e23","text-align":"center"},on:{"click":function($event){return _vm.showregwin()}}},[_vm._v(" 注册账号 ")])]),_c('van-popup',{staticClass:"popup",style:({ height: '90%' }),attrs:{"round":"","closeable":"","position":"bottom"},model:{value:(_vm.showreg),callback:function ($$v) {_vm.showreg=$$v},expression:"showreg"}},[_c('div',{staticClass:"intro-bind black-font"},[_c('div',[_vm._v("注册账号")]),_c('div',{staticClass:"intro-sub-bind black-font"},[_vm._v("将 Justap 与您的账号连接")]),_c('div',{staticStyle:{"padding-top":"5vh"}}),_c('van-form',{on:{"submit":_vm.regaction}},[_c('van-field',{attrs:{"name":"用户名","label":"用户名","placeholder":"用户名","rules":[
            {
              required: true,
              validator: _vm.valdusername,
              message: this.userhint,
            } ]},model:{value:(_vm.regusername),callback:function ($$v) {_vm.regusername=$$v},expression:"regusername"}}),_c('van-field',{attrs:{"type":"password","name":"密码","label":"密码","placeholder":"密码","rules":[
            {
              required: true,
              validator: _vm.valdpass,
              message: this.passwordhint,
            } ]},model:{value:(_vm.regpassword),callback:function ($$v) {_vm.regpassword=$$v},expression:"regpassword"}}),_c('van-field',{attrs:{"type":"password","name":"重复密码","label":"重复密码","placeholder":"重复密码","rules":[
            { required: true, validator: _vm.valdpassco, message: '密码不一致' } ]},model:{value:(_vm.passwordcorm),callback:function ($$v) {_vm.passwordcorm=$$v},expression:"passwordcorm"}}),_c('div',{staticStyle:{"padding-top":"5vh"}}),_c('van-button',{attrs:{"color":"linear-gradient(to right, #ff6034, #ee0a24)","size":"large"}},[_vm._v("注册")])],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro"},[_c('div',[_vm._v("登入 👋")]),_c('div',{staticClass:"intro-sub"},[_vm._v("欢迎使用 Justap")])])}]

export { render, staticRenderFns }