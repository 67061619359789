<template>
  <div>
    <div class="body">
      <div class="intro">
        <div>登入 👋</div>
        <div class="intro-sub">欢迎使用 Justap</div>
      </div>
      <div style="padding-top: 5vh"></div>

      <div class="bind-btn">
        <van-form @submit="login">
          <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <div style="padding-top: 5vh"></div>
          <van-button
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            size="large"
            >登入</van-button
          >
        </van-form>
      </div>
      <div
        v-on:click="showregwin()"
        style="
          padding-top: 2vh;
          font-size: 1.8vh;
          color: #c42e23;
          text-align: center;
        "
      >
        注册账号
      </div>
    </div>
    <van-popup
      class="popup"
      v-model="showreg"
      round
      closeable
      position="bottom"
      :style="{ height: '90%' }"
    >
      <div class="intro-bind black-font">
        <div>注册账号</div>
        <div class="intro-sub-bind black-font">将 Justap 与您的账号连接</div>
        <div style="padding-top: 5vh"></div>
        <van-form @submit="regaction">
          <van-field
            v-model="regusername"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[
              {
                required: true,
                validator: valdusername,
                message: this.userhint,
              },
            ]"
          />
          <van-field
            v-model="regpassword"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[
              {
                required: true,
                validator: valdpass,
                message: this.passwordhint,
              },
            ]"
          />
          <van-field
            v-model="passwordcorm"
            type="password"
            name="重复密码"
            label="重复密码"
            placeholder="重复密码"
            :rules="[
              { required: true, validator: valdpassco, message: '密码不一致' },
            ]"
          />
          <div style="padding-top: 5vh"></div>
          <van-button
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            size="large"
            >注册</van-button
          >
        </van-form>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { login, userexist, reg } from "@/api/store";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      regusername: "",
      regpassword: "",
      passwordcorm: "",
      userhint: "",
      passwordhint: "",
      islogin: false,
      showreg: false,
    };
  },
  created() {
    if (localStorage.getItem("token") != null) {
        // TODO 验证 token 是否有效
      this.islogin = true;
      this.$router.push({ name: "Mine"})
    }
    this.init();
  },
  methods: {
    init() {
      // 请求信息
      this.tag = localStorage.getItem("newtagid");
    },
    showregwin() {
      this.showreg = true;
    },
    regaction() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      reg({
        username: this.regusername,
        password: this.regpassword,
      }).then((response) => {
        if (response.data.errno == 0) {
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("uuid", response.data.data.userinfo.uuid);
            localStorage.setItem("id", response.data.data.userinfo.id);
            localStorage.setItem(
              "userinfo",
              JSON.stringify(response.data.data.userinfo)
            );
          this.$toast.success("成功注册");
          this.showreg = false;
          this.$router.back();
        } else {
          this.$toast.fail("服务器开小差了");
        }
      });
    },
    login() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      login({ username: this.username, password: this.password }).then(
        (response) => {
          if (response.data.errno == 1403) {
            this.$toast.fail("密码错误");
          } else if (response.data.errno == 1404) {
            this.$toast.fail("用户不存在");
          } else if (response.data.errno == 0) {
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("uuid", response.data.data.userinfo.uuid);
            localStorage.setItem("id", response.data.data.userinfo.id);
            localStorage.setItem(
              "userinfo",
              JSON.stringify(response.data.data.userinfo)
            );
            this.$toast.success("成功登入");
            this.$router.back();
          }
        }
      );
      this.$toast.clear();
    },
    async valdusername(val) {
      if (this.regusername == "") {
        this.userhint = "请输入用户名";
        return false;
      }
      let res = await userexist({ username: this.regusername }).then(
        (response) => {
          if (response.data.errno == 1409) {
            this.userhint = "用户名已被使用";
            return false;
          } else if (response.data.errno == 0) {
            return true;
          }
        }
      );
      if (res == false) {
        return false;
      }
      let vald = /^[A-Za-z][A-Za-z0-9]*$/.test(val);
      if (vald == false) {
        this.userhint = "用户名只能含有英文字母和数字";
        return false;
      }
      return true;
    },
    valdpass(val) {
      if (val.length < 8) {
        this.passwordhint = "密码需要长于 8 位";
        return false;
      }
    },
    valdpassco(val) {
      if (this.regpassword == val) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.black-font {
  color: black !important;
  /* background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36)) !important; */
}
.popup {
  background-color: rgba(255, 255, 255, 1);
}
.blur {
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 75%,
    rgba(0, 0, 0, 1) 100%
  );
  display: inline-block;
}
.body {
  background-color: #030303;
  height: 100vh;
}
.intro {
  color: white;
  padding-left: 5vh;
  padding-top: 10vh;
  font-size: 5vh;
}
.intro-bind {
  color: white;
  padding-left: 4vh;
  padding-right: 4vh;
  padding-top: 10vh;
  font-size: 4vh;
}
.intro-sub {
  color: white;
  padding-top: 1vh;
  font-size: 3vh;
}
.intro-sub-bind {
  color: white;
  padding-top: 1vh;
  font-size: 2vh;
}
.bio {
  color: white;
  padding-left: 5vh;
  padding-right: 5vh;
  padding-top: 4vh;
  padding-bottom: 4vh;
}
.bind-btn {
  margin-left: 3vh;
  margin-right: 3vh;
}
.van-grid-item__content {
  border-radius: 10%;
}
.van-grid-item__icon {
  font-size: 6vh !important;
}
.van-icon__image {
  width: 1em;
  height: 1em;
  border-radius: 20%;
  object-fit: contain;
}
.van-grid-item__content--surround::after {
  border-width: 0px;
}
.van-grid-item__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 16px 8px;
  background-color: #000;
}
.van-grid-item__text {
  color: #ffffff;
  font-size: 12px;
  line-height: 1.5;
  word-break: break-all;
}
</style>